import React from "react";
import Texts from "../../../utils/lang-utils";
import tabletAppDemo from "../../../areas/main/assets/videos/kitchen-app.mp4";
import urls from "../../../areas/main/assets/urls";
import "./KitchenAppSection.scss";

const KitchenAppSection = () => {
  return (
    <>
      <section className="cta-two kitchen-app">
        <div className="container">
          <div className="row h-100">


            <div className="col-lg-12">
              <div className="cta-two__content text-center">
                <div className="block-title ">
                  <h2 className="block-title__title" dangerouslySetInnerHTML={{ __html: Texts.Apps.KitchenAppTitle }}></h2>
                  <h3 className="block-title__subtitle" dangerouslySetInnerHTML={{ __html: Texts.Apps.KitchenAppSubtitle }}></h3>
                </div>

                <div className="cta-one__moc">
          <video muted loop autoPlay playsInline>
                  <source src={tabletAppDemo} className="cta-two__moc" type="video/mp4"></source>
              </video>
        </div>





                <div className="text-center">
                  <a href={urls.onboarding} className="thm-btn mt-3 mobile-hide">
                    <span>{Texts.GetStarted}</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mobile-hide d-none d-xl-block" style={{ marginTop: 'auto', marginBottom: 'auto' }}>

            </div>
          </div>
          <a href={urls.onboarding} className="thm-btn mt-3 mobile-show" style={{ display: 'none' }}>
            <span>{Texts.GetStarted}</span>
          </a>
        </div>
      </section>
    </>

  );
};
export default KitchenAppSection;
